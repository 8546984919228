const colors = {
  mitRed: "#750014",
  brightRed: "#FF1423",
  black: "#000000",
  white: "#FFFFFF",
  darkGray2: "#212326",
  darkGray1: "#40464C",
  silverGrayDark: "#626A73",
  silverGray: "#8B959E",
  silverGrayLight: "#B8C2CC",
  lightGray2: "#DDE1E6",
  lightGray1: "#F3F4F8",
  navGray: "#303337",
  darkPink: "#750062",
  pink: "#FF14F0",
  lightPink: "#FFB3FF",
  darkPurple: "#3E006B",
  purple: "#93F",
  lightPurple: "#BFB3FF",
  darkBlue: "#002896",
  blue: "#1966FF",
  lightBlue: "#99EBFF",
  darkGreen: "#004D1A",
  green: "#00AD00",
  lightGreen: "#AF3",
  darkRed: "#83192A",
  red: "#A31F34",
  lightRed: "#D02E44",
  orange: "#FAB005",
  yellow: "#FFEB00",
}

export { colors }
